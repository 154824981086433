import React from 'react';
import { Link } from "gatsby"

// creates a link to the photographer entity or:
// writes: 'no photographer credited'
const PhotographerResolver = (photo) => {
    console.log('in PhotographerResolver (#ergvfdon). photo = ', photo)

    if (photo == null) {

        return '[no photograph provided.]'
    }

    var relatedPhotographer = photo.relationships.field_photographer

    if (relatedPhotographer) {
        return (
            <>
                <Link to={relatedPhotographer.fields.slug}>
                    {relatedPhotographer.title}
                </Link>
            </>
        )
    }

    if (photo.field_photo_photographer) {
        const fieldPhotographer = photo.field_photo_photographer
        return (
            <a target="_new" href={fieldPhotographer.uri}>{fieldPhotographer.title}</a>
        )
    }

    if (photo == null) {

        return '[no photograph provided.]'
    }

    return '[no photographer credited]'
};
 
export default PhotographerResolver;