// returns the featured image for any Drupal content node (e.g. location, itineary, activity)
const getFeaturedImage = (node) => {

    var type = node.__typename
    console.log ('_typename (#dv34fvsdfg): ', type)
    
    switch (type) {
        case 'node__itinerary':
            return node.relationships.field_itin_feature_image
        case 'node__activity':
            return node.relationships.field_activity_feature_photo
        case 'node__location':
            return node.relationships.field_locat_featured_photograph
        case 'node__photograph':
            return node
        default:
            throw new Error ('unknown content type: ' + type + '. Please create a switch case for it (#3zxc24rwfssdf).')
    }
}

export default getFeaturedImage;