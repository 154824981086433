import styled from "styled-components"

export const Tiled = styled.div`
  // @media only screen and (max-width: 576px) {
    
  //     // border:               1px solid #0f0;
      
  //     -moz-column-count:    1;
  //     -webkit-column-count: 1;

      
    
  // }
  // column-count: 2;
  // column-width: 300px;
  // column-gap: 1em;
  // border:               3px solid #00f;
  // margin: 5px;

  div {
    // display: inline-block;    // this fixes the default clipping
    // width: 100%;
    // border:               3px solid #00f;
  }
`