import React from 'react';

import { HighlightDetailsContainer, Col2 } from "./styles"

import Row from 'react-bootstrap/Row';

import PhotographerResolver from 'functions/PhotographerResolver'

import TypeMarker from "components/TypeMarker"

import getFeaturedImage from 'functions/getFeaturedImage'

const HighlightDetailsTable = ({highlight, detailLevel, layout}) => {
    var photo = getFeaturedImage(highlight)
    console.log('in HighlightDetailsTable() (#41235tf98j). highlight = ', highlight)
    console.log('(#41235tf923r238j). photo = ', photo)
    
    var left_lg, right_lg, left_md, right_md, left_sm, right_sm
    if (layout == 'large') {
        left_lg   = 3
        right_lg  = (12 - left_lg)
    
        left_md   = 4
        right_md  = (12 - left_md)
    
        left_sm   = 12
        right_sm  = 12
    }
    
    if (layout == 'compact') {
        left_lg   = 5
        right_lg  = (12 - left_lg)
    
        left_md   = 5
        right_md  = (12 - left_md)
    
        left_sm   = 12
        right_sm  = 12
    }

    return (
        <HighlightDetailsContainer>
            <Row>
                <Col2 xs="12" md="12" lg="12">
                    
                    <TypeMarker node={highlight}></TypeMarker>     
                    &nbsp;Photo by: { PhotographerResolver(photo) } 
                </Col2>
                {/* <Col className="Left" xs={left_sm} md={left_md} lg={left_lg}>
                    Photographer:
                </Col>
                <Col className="Right" xs={right_sm} md={right_md} lg={right_lg}>
                    { PhotographerResolver(photo) }
                </Col> */}
            </Row>
            {/* <Row>
                <Col className="Left" xs={left_sm} md={left_md} lg={left_lg}>
                    Type:
                </Col>
                <Col className="Right" xs={right_sm} md={right_md} lg={right_lg}>
                    { typeMark }

                </Col>
            </Row> */}
            { detailLevel == 'all' && 
            <>
                <Row>
                    <Col2 className="Left" xs={left_sm} md={left_md} lg={left_lg}>
                        Itineraries that visit this highlight:
                        Activities that visit this highlight:
                        ?
                    </Col2>
                    <Col2 className="Right" xs={right_sm} md={right_md} lg={right_lg}>
                        Startlocation -> Activity -> Endlocation
                        B
                        C
                    </Col2>
                </Row>
            </>
            }
        </HighlightDetailsContainer>
    )
};

export default HighlightDetailsTable;