import styled from "styled-components"
import { CustomContainer } from 'styles/CustomContainer.styles'
import Col from 'react-bootstrap/Col';

import { colors } from 'styles/GlobalStyles.js'

export const HighlightDetailsContainer = styled(CustomContainer)`

  /* border: 1px solid #0f0; */
  /* padding: 2px 7px; */
  padding-left: 0px;

  .Left {
    // background-color: #f90061;

    // border: 20px solid ${colors.legendHighlight};
    background-color: ${colors.legendHighlight};

    margin-bottom: 4px;
    color: #000;
  }

  .Right {
    /* border: 1px solid #0f0; */
    padding-left: 10px;
  }
`

export const Col2 = styled(Col)`

  /* border: 1px solid #0f0; */
  /* padding: 2px 7px; */
  
  padding-left: 0px !important;

  .Left {
    // background-color: #f90061;

    // border: 20px solid ${colors.legendHighlight};
    background-color: ${colors.legendHighlight};

    margin-bottom: 4px;
    color: #000;
  }

  .Right {
    /* border: 1px solid #0f0; */
    padding-left: 10px;
  }
`