import React from "react"
import { graphql } from "gatsby"
// Components
import Layout from "components/Layout"
import SEO from "components/SEO"

// import LocationTeaser from 'components/LocationTeaser'
import ItinDetailsTable from 'components/ItinDetailsTable'

import DescriptionBox from 'components/DescriptionBox'

import HighlightsList from 'components/HighlightsList'

import { PhotographTypeMark, LocationTypeMark } from "styles/GlobalStyles.js"
import SaveButtonContainer from "../components/SavedItems/SaveButtonContainer"

const IndexPage = ({ data, pageContext }) => {
  const itin              = data.allNodeItinerary.edges[0].node
  const title             = itin.title
  const highlights        = itin.relationships.field_itin_featured_locations  /// originally was only locations, now also has photographs too, so we refer to is as "highlights", but the drupal field could not be updated.
  console.log('itinerary highlights (#59cnaoi): ', highlights)

  const html = () => {
    return (
      <p>
        Note: highlights can be <LocationTypeMark>locations</LocationTypeMark> (aka “Points Of Interest”) or <PhotographTypeMark>photographs</PhotographTypeMark> (photographs are usually not related to a specific location, for instance; birds, animals, trees and flowers in the are - that don’t have a fixed location)..
      </p>
    )
  }
  
  return (
    <Layout>
      <SEO title={title} />
      <main>
        <SaveButtonContainer node={itin}>
          <ItinDetailsTable itin={itin} detailLevel={"detailed"} layout={"large"} viewMode={"highlights"} headerText={title} />
        </SaveButtonContainer>
      
        <DescriptionBox title={"Hightlights mode"} headerClass={"highlights"} html={html()} useUnprotected={true} />

        <HighlightsList highlights={highlights} />

      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query FindItineraryAndFeaturedLocationsByNId($nid: Int) {
  allNodeItinerary(filter: {drupal_internal__nid: {eq: $nid}}) {
    edges {
      node {
        __typename
        id
        drupal_id
        drupal_internal__nid
        fields {
          slug
          slug_highlights
          slug_activities
        }
        path {
          alias
        }
        title
        body {
          value
          processed
        }
        field_activity_length
        field_distance_in_time_
        field_itin_transport_mode
        relationships {
          field_starting_location {
            title
            fields {
              slug
            }
            relationships {
              field_state {
                title
                relationships {
                  field_states_country {
                    title
                    drupal_id
                  }
                }
                drupal_id
              }
            }
          }
          field_itinerary_activities {
            drupal_internal__nid
            relationships {
              field_step_photographs {
                drupal_internal__nid
              }
            }
          }
          field_itin_feature_image {
            drupal_id
            relationships {
              field_photo_images {
                drupal_id
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 1200, maxHeight: 600, cropFocus: CENTER
                    ) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          field_variant_itineraries {
            id
            drupal_internal__nid
            title
            fields {
              slug
              slug_highlights
              slug_activities
            }
          }
          field_itin_featured_locations {
            ... on node__photograph {
              drupal_id
              drupal_internal__nid
              field_is_4x3_available
              field_photo_photographer {
                title
                uri
              }
              title
              relationships {
                field_photo_images {
                  localFile {
                    childImageSharp {
                      fluid(
                        maxWidth: 600, maxHeight: 750, cropFocus: CENTER
                      ) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                field_photographer {
                  drupal_internal__nid
                  drupal_id
                  title
                  fields {
                    slug
                  }
                }
              }
            }
            ... on node__location {
              drupal_internal__nid
              drupal_id
              title
              fields {
                slug
              }
              relationships {
                field_locat_featured_photograph {
                  drupal_id
                  drupal_internal__nid
                  field_is_4x3_available
                  field_photo_photographer {
                    title
                    uri
                  }
                  title
                  relationships {
                    field_photo_images {
                      localFile {
                        childImageSharp {
                          fluid(
                            maxWidth: 600, maxHeight: 750, cropFocus: CENTER
                          ) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                    field_photographer {
                      drupal_internal__nid
                      drupal_id
                      title
                      fields {
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`