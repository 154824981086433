import React from "react"
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import HighlightDetailsTable from 'components/HighlightDetailsTable'

import { HighlightTypeMark } from "styles/GlobalStyles.js"

import FeatureImage from 'components/FeatureImage'

// import ActivityDetailsTable from "components/ActivityDetailsTable"

const HighlightHeading = ({highlight}) => {
    var heading
    
    if (highlight.__typename == 'node__location') {
        heading = (
            <Link to={highlight.fields.slug}>
                {highlight.title}
            </Link>
        )
    } else {
        heading = highlight.title
    }

    return (
        <h2>
            {heading}
        </h2>
    )
}

const HighlightTeaser = ({ step, highlight }) => {
    var stepText = "Highlight #" + step + ":"
    console.log('Current highlight (#e2fc2n9u): ', highlight)
    // console.log('Current location.relationships.field_locat_featured_photograph.relationships[0].field_photo_images [0] #e2fc2n9u: ', highlight.relationships.field_locat_featured_photograph.relationships)

    // get the photograph entity (depending on if the entity is a location or already a photograph)
    var locationsPhotograph = highlight.relationships.field_locat_featured_photograph
    var type = highlight.__typename

    var photograph
    if (type == 'node__location') {
        photograph = locationsPhotograph
    } else if (type == 'node__photograph') {
        photograph = highlight
    } else {
        return (
            <div>
                <HighlightTypeMark>{stepText}</HighlightTypeMark>
                <p>Error: content type of: '{ type }' cannot be used as a highlight (id: { highlight.drupal_internal__nid })</p>
            </div>
        )
    }
    
    return (
        <div>
            <HighlightTypeMark>{stepText}</HighlightTypeMark>
            
            <HighlightHeading highlight={highlight}></HighlightHeading>
            
            <HighlightDetailsTable highlight={highlight} detailLevel={"large"} layout={"compact"} />
            <FeatureImage featureImage={photograph} aspectRatio='1x1' />
            
        </div>
    )
}

export default HighlightTeaser