import React from "react"
import HighlightTeaser from "components/HighlightTeaser"
import { Tiled } from "components/HighlightsList/styles.js"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// display a tiled list of "highlights" (locations and photographs)
const HighlightsList = ({ highlights }) => {
    return (
        <Tiled>
            <Container style={{width: "100%" }}>
            <Row>

            {highlights.map((highlight, index) => {
                console.log ('current highlight (#f0fmkff): ', highlight)
                // console.log ('counter: ', index)
                
                // determine if it's a location content type or photograph content type
                // var component1 = 'test'
                var component1 = <HighlightTeaser step={index+1} highlight={highlight} />
                // if (highlight.title != null) {
                //     // location type
                    
                // } else {
                //     // photograph type
                //     var component1 = 'test'
                // }

                return (
                    
                        <Col xs="12" md="12" lg="6">
                            <div key={highlight.id}>
                                { component1 }
                            </div>
                        </Col>
                    
                )
            })}
            </Row>
            </Container>
        </Tiled>
    )
}

export default HighlightsList